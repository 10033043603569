import React from "react";
import { AppBar, Toolbar, Box, IconButton, Avatar,Typography } from "@mui/material";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: "white",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box
          onClick={() => window.location.replace("/dashboard    ")}
          component="img"
          src="/resolve_logo.png" // Path to your logo
          alt="Resolve Logo"
          sx={{ width: 40, height: 40 }}
        />
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton component={Link} to="/settings" sx={{ color: "grey" }}>
            <SettingsOutlinedIcon />
          </IconButton>
          <IconButton
            component={Link}
            to="/notifications"
            sx={{ color: "grey" }}
          >
            <NotificationsOutlinedIcon />
          </IconButton>
          <IconButton component={Link} to="/profile" sx={{ color: "grey" }}>
            <Avatar sx={{ bgcolor: "#F2F4F7" }}><Typography sx={{color:"#000000"}}>SJ</Typography></Avatar>
          </IconButton>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
