import React, { useState } from "react";
import {
  Container,
  Typography,
  Box,
  TextField,
  Button,
  Link,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useNavigate } from "react-router-dom";


const SetNewPassword = () => {
    const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleResetPassword = () => {
    // Handle the reset password action (e.g., form submission)
    console.log({
      password,
      confirmPassword,
    });
    navigate("/all_done");// Redirect to the all done page if the password is successfully reset
  };

  return (
    <Container sx={{ marginTop: 8 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          maxWidth: "400px",
          margin: "auto",
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: 400,
            textAlign: "center",
            marginBottom: 4,
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <img
            src="resolve_logo.png"
            alt="Logo"
            style={{ width: 60, height: 60, marginBottom: 16 }}
          />
        </Box>
        <Typography
          variant="h4"
          sx={{
            fontWeight: 600,
            fontStyle: "normal",
            fontFamily: "var(--Font-1)",
            color: "var(--Gray-900, #101828)",
            marginBottom: "16px",
            fontSize: "30px",
          }}
        >
          Set new password
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontFamily: "var(--Font-1)",
            color: "var(--Gray-700, #344054)",
            textAlign: "center",
            marginBottom: "32px",
          }}
        >
          Your new password must be different to previously used passwords.
        </Typography>
        <TextField
          label="Password"
          type="password"
          variant="outlined"
          fullWidth
          margin="normal"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          sx={{
            marginBottom: "16px",
            "& .MuiOutlinedInput-root": {
              borderRadius: "8px",
            },
          }}
        />
        <TextField
          label="Confirm password"
          type="password"
          variant="outlined"
          fullWidth
          margin="normal"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          sx={{
            marginBottom: "16px",
            "& .MuiOutlinedInput-root": {
              borderRadius: "8px",
            },
          }}
        />
        <Button
          variant="contained"
          fullWidth
          onClick={handleResetPassword}
          sx={{
            background: "linear-gradient(90deg, #AD2B45 0%, #0084D1 100%)",
            borderRadius: "8px",
            height: "50px",
            fontWeight: 700,
            textTransform: "none",
            marginBottom: "16px",
          }}
        >
          Reset Password
        </Button>
        <Link
            href="/login"
            sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                color: "var(--Gray-700, #344054)",
                textDecoration: "none",
                fontWeight: 600,
                fontSize: "14px",
            }}
            >
            <ArrowBackIosNewIcon fontSize="" />
            Back to log in
            </Link>
      </Box>
    </Container>
  );
};

export default SetNewPassword;
