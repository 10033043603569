import React, { useState } from "react";
import {
  Container,
  Typography,
  Box,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Pagination,
  TableFooter,
  Menu,
  MenuItem,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Link } from "react-router-dom";
import DeleteEntityDialog from "../../components/delete_entity_dialog";

const exercises = [
  {
    id: 1,
    name: "Jane Cooper",
    email: "jane.cooper@example.com",
    phone: "1234567890",
  },
  {
    id: 2,
    name: "Arlene McCoy",
    email: "arlene.mccoy@example.com",
    phone: "1234567890",
  },
  {
    id: 3,
    name: "Floyd Miles",
    email: "floyd.miles@example.com",
    phone: "1234567890",
  },
  {
    id: 4,
    name: "Courtney Henry",
    email: "courtney.henry@example.com",
    phone: "1234567890",
  },
  {
    id: 5,
    name: "Albert Flores",
    email: "albert.flores@example.com",
    phone: "1234567890",
  },
  {
    id: 6,
    name: "Annette Black",
    email: "annette.black@example.com",
    phone: "1234567890",
  },
  {
    id: 7,
    name: "Dianne Russell",
    email: "dianne.russell@example.com",
    phone: "1234567890",
  },
  {
    id: 8,
    name: "Esther Howard",
    email: "esther.howard@example.com",
    phone: "1234567890",
  },
  {
    id: 9,
    name: "Eleanor Pena",
    email: "eleanor.pena@example.com",
    phone: "1234567890",
  },
  {
    id: 10,
    name: "Robert Fox",
    email: "robert.fox@example.com",
    phone: "1234567890",
  },
  // Add more exercises as needed
];

const ManageUsers = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteClick = (user) => {
    setSelectedUser(user);
    setIsDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = () => {
    // Perform the delete action
    console.log("Deleted user:", selectedUser);
    setIsDeleteDialogOpen(false);
  };

  return (
    <Container sx={{ marginTop: 4 }}>
      <Button
        variant="text"
        startIcon={
          <ArrowBackIosNewIcon
            sx={{
              width: "17px",
              height: "17px",
              color: "var(--Gray-900, #101828)",
            }}
          />
        }
        sx={{
          marginBottom: 2,
          fontWeight: 700,
          fontStyle: "normal",
          fontFamily: "var(--Font-1)",
          color: "var(--Gray-900, #101828)",
        }}
        component={Link}
        to="/dashboard"
      >
        <Typography
          sx={{
            fontSize: "13px",
            fontStyle: "normal",
            fontFamily: "var(--Font-1)",
            color: "var(--Gray-900, #101828)",
            fontWeight: 700,
          }}
        >
          Back to Dashboard
        </Typography>
      </Button>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 2,
        }}
      >
        <Typography
          sx={{
            fontFamily: "var(--Font-1)",
            fontWeight: 700,
            color: "var(--Gray-900, #101828)",
          }}
          variant="h4"
          gutterBottom
        >
          Manage users
        </Typography>
        <Button
          variant="contained"
          sx={{
            background: "var(--Blue-light-500, #0BA5EC)",
            borderRadius: "8px",
            fontWeight: 600,
          }}
          startIcon={<AddIcon />}
        >
          Add user
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 2,
        }}
      >
        <TextField
          label="Search"
          variant="outlined"
          sx={{ flexGrow: 1, marginRight: 2 }}
        />
        <Button
          variant="outlined"
          sx={{
            marginRight: 1,
            borderRadius: "8px",
            height: 50,
            width: 90,
            fontWeight: 700,
            color: "var(--Gray-700, #344054)",
            borderColor: "var(--Gray-700, #344054)",
          }}
        >
          Clear
        </Button>
        <Button
          variant="contained"
          sx={{
            background: "var(--Blue-light-500, #0BA5EC)",
            borderRadius: "8px",
            height: 50,
            width: 90,
            fontWeight: 700,
          }}
        >
          Search
        </Button>
      </Box>

      <TableContainer component={Paper} sx={{ marginBottom: 8 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={6}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 2,
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      variant="h6"
                      sx={{
                        marginRight: 2,
                        fontFamily: "var(--Font-1)",
                        fontWeight: 700,
                        color: "var(--Gray-700, #344054)",
                      }}
                    >
                      All Users
                    </Typography>
                  </Box>
                  <IconButton onClick={handleMenuClick}>
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleMenuClose}
                  >
                    <MenuItem onClick={handleMenuClose}>Option 1</MenuItem>
                    <MenuItem onClick={handleMenuClose}>Option 2</MenuItem>
                    <MenuItem onClick={handleMenuClose}>Option 3</MenuItem>
                  </Menu>
                </Box>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableHead>
            <TableRow>
              <TableCell>
                <Typography
                  sx={{ fontWeight: 700, color: "var(--Gray-600, #475467)" }}
                >
                  Sr. Num.
                </Typography>
              </TableCell>
              <TableCell sx={{ paddingRight: "400px" }}>
                <Typography
                  sx={{ fontWeight: 700, color: "var(--Gray-600, #475467)" }}
                >
                  Name
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  sx={{ fontWeight: 700, color: "var(--Gray-600, #475467)" }}
                >
                  Email
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  sx={{ fontWeight: 700, color: "var(--Gray-600, #475467)" }}
                >
                  Phone Number
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {exercises.map((exercise, index) => (
              <TableRow
                key={exercise.id}
                sx={{ backgroundColor: index % 2 === 0 ? "#f9f9f9" : "white" }}
              >
                <TableCell>
                  <Typography
                    sx={{ fontWeight: 700, color: "var(--Gray-600, #475467)" }}
                  >
                    {index + 1}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    sx={{ fontWeight: 700, color: "var(--Gray-600, #475467)" }}
                  >
                    {exercise.name}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={{ color: "var(--Gray-600, #475467)" }}>
                    {exercise.email}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={{ color: "var(--Gray-600, #475467)" }}>
                    {exercise.phone}
                  </Typography>
                </TableCell>

                <TableCell align="right">
                  <IconButton
                    color="black"
                    onClick={() => handleDeleteClick(exercise)}
                  >
                    <DeleteOutlinedIcon />
                  </IconButton>
                  <IconButton color="black">
                    <EditOutlinedIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={6}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    color: "black",
                    borderColor: "black",
                  }}
                >
                  <Button
                    sx={{
                      marginRight: 1,
                      borderRadius: "8px",
                      fontWeight: 700,
                      color: "var(--Gray-700, #344054)",
                      borderColor: "var(--Gray-700, #344054)",
                    }}
                    variant="outlined"
                    startIcon={<ArrowBackIosNewIcon />}
                  >
                    Previous
                  </Button>
                  <Pagination count={10} variant="outlined" shape="rounded" />
                  <Button
                    sx={{
                      marginLeft: 10,
                      borderRadius: "8px",
                      fontWeight: 700,
                      color: "var(--Gray-700, #344054)",
                      borderColor: "var(--Gray-700, #344054)",
                    }}
                    variant="outlined"
                    endIcon={<ArrowForwardIosIcon />}
                  >
                    Next
                  </Button>
                </Box>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <DeleteEntityDialog
        open={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        onDelete={handleDeleteConfirm}
        entity="User"
      />
    </Container>
  );
};

export default ManageUsers;
