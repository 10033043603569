import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Typography,
  Box,
  Chip,
  Paper,
  Grid,
  Button,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useLocation, Link } from "react-router-dom";
import {
  PoseLandmarker,
  FilesetResolver,
  DrawingUtils,
} from "https://cdn.skypack.dev/@mediapipe/tasks-vision@0.10.0";

const ExerciseDetails = () => {
  const location = useLocation();
  const { exercise } = location.state;
  const [poseLandmarker, setPoseLandmarker] = useState(null);
  const [isDetecting, setIsDetecting] = useState(true);
  const isMounted = useRef(true);

  useEffect(() => {
    isMounted.current = true;
    const createPoseLandmarker = async () => {
      const vision = await FilesetResolver.forVisionTasks(
        "https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.0/wasm"
      );
      const poseLandmarker = await PoseLandmarker.createFromOptions(vision, {
        baseOptions: {
          modelAssetPath: `https://storage.googleapis.com/mediapipe-models/pose_landmarker/pose_landmarker_lite/float16/1/pose_landmarker_lite.task`,
          delegate: "GPU",
        },
        runningMode: "IMAGE",
        numPoses: 2,
      });
      if (isMounted.current) {
        setPoseLandmarker(poseLandmarker);
      }
    };
    createPoseLandmarker();
    return () => {
      isMounted.current = false;
    };
  }, [exercise]);

  useEffect(() => {
    const detectPoses = async () => {
      if (poseLandmarker && exercise?.steps) {
        for (const step of exercise.steps) {
          if (!isMounted.current) break;
          const image = new Image();
          image.src = `data:image/png;base64,${step.image}`;
          await new Promise((resolve) => {
            image.onload = async () => {
              const canvas = document.getElementById(
                `canvas-${step.sequence_number}`
              );
              if (!isMounted.current || !canvas) {
                resolve();
                return;
              }
              const canvasCtx = canvas.getContext("2d");
              if (!canvasCtx) {
                resolve();
                return;
              }
              canvas.width = image.naturalWidth;
              canvas.height = image.naturalHeight;
              canvasCtx.clearRect(0, 0, canvas.width, canvas.height);
              canvasCtx.drawImage(image, 0, 0, canvas.width, canvas.height);

              const result = await poseLandmarker.detect(image);
              if (result.landmarks.length > 0) {
                const drawingUtils = new DrawingUtils(canvasCtx);
                result.landmarks.forEach((landmarks) => {
                  drawingUtils.drawLandmarks(landmarks, {
                    radius: (data) =>
                      DrawingUtils.lerp(
                        data.from && data.from.z ? data.from.z : 0,
                        -0.15,
                        0.1,
                        5,
                        1
                      ),
                  });
                  drawingUtils.drawConnectors(
                    landmarks,
                    PoseLandmarker.POSE_CONNECTIONS
                  );
                });
              }
              resolve();
            };
          });
        }
        setIsDetecting(false);
      }
    };
    detectPoses();
  }, [poseLandmarker, exercise]);

  return (
    <Container sx={{ marginTop: 4 }}>
      <Button
        variant="text"
        startIcon={
          <ArrowBackIosNewIcon
            sx={{
              width: "17px",
              height: "17px",
              color: "var(--Gray-900, #101828)",
            }}
          />
        }
        sx={{
          marginBottom: 2,
          fontWeight: 700,
          fontStyle: "normal",
          fontFamily: "var(--Font-1)",
          color: "var(--Gray-900, #101828)",
        }}
        component={Link}
        to="/dashboard"
        disabled={isDetecting}
      >
        <Typography
          sx={{
            fontSize: "13px",
            fontStyle: "normal",
            fontFamily: "var(--Font-1)",
            color: "var(--Gray-900, #101828)",
            fontWeight: 700,
          }}
        >
          Back to Dashboard
        </Typography>
      </Button>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 2,
        }}
      >
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            fontFamily: "var(--Font-1)",
            fontWeight: 700,
            color: "var(--Gray-900, #101828)",
            paddingRight: 113,
          }}
        >
          {exercise?.name ?? "Exercise Name"}
        </Typography>
      </Box>

      <Typography
        variant="body1"
        sx={{
          marginBottom: 2,
          fontFamily: "var(--Font-1)",
          color: "var(--Gray-700, #344054)",
        }}
      >
        {exercise?.description ?? "Exercise Description"}
      </Typography>

      <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap", marginBottom: 4 }}>
        {exercise?.tags?.map((tag, tagIndex) => (
          <Chip
            key={tagIndex}
            label={tag}
            variant="outlined"
            sx={{
              fontWeight: 700,
              color: "#175CD3",
              borderColor: "#B2DDFF",
              backgroundColor: "#EFF8FF",
            }}
          />
        ))}
      </Box>

      <Grid container spacing={2} marginBottom={4}>
        {exercise?.steps?.map((step) => (
          <Grid item md={4} key={step.sequence_number}>
            <Paper
              sx={{
                borderRadius: 3,
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{ position: "relative", width: "100%", height: "200px" }}
              >
                <img
                  src={`data:image/png;base64,${step.image}`}
                  alt={`Step ${step.sequence_number}`}
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
                <canvas
                  id={`canvas-${step.sequence_number}`}
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    pointerEvents: "none",
                  }}
                />
              </Box>
              <Box sx={{ padding: 2, flexGrow: 1 }}>
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{
                    fontFamily: "var(--Font-1)",
                    fontWeight: 700,
                    color: "var(--Gray-900, #101828)",
                  }}
                >
                  Step {step.sequence_number}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: "var(--Font-1)",
                    color: "var(--Gray-700, #344054)",
                    overflowWrap: "break-word",
                  }}
                >
                  {step.description}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: "var(--Font-1)",
                    color: "var(--Gray-700, #344054)",
                    marginTop: 1,
                  }}
                >
                  Counts: {step.counts}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: "var(--Font-1)",
                    color: "var(--Gray-700, #344054)",
                    marginTop: 1,
                  }}
                >
                  Step Duration: {step.countDuration} seconds
                </Typography>
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default ExerciseDetails;
