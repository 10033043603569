import axios from "axios";
import baseURL  from "../../resources/constants";

async function createExercise(data) {
  console.log("Data for exercise is:", data);
  try {
    const endpoint = `${baseURL}/exercises`;
    
    const response = await axios.post(endpoint, data);
    if(response.status === 200) {
      console.log("Exercise created successfully");
      return true;
    }
    console.log("Response from server is:", response.data);
    return false;
  } catch (error) {
    throw new Error(error.message);
  }
}

async function getExercises() {
  try {
    const endpoint = `${baseURL}/exercises`;
    const response = await axios.get(endpoint);
    if(response.status === 200) {
      console.log("Exercises fetched successfully");
      return response.data;
    }
    console.log("Response from server is:", response.data);
    return [];
  } catch (error) {
    throw new Error(error.message);
  }
}

async function deleteExercise(id) {
  try {
    const endpoint = `${baseURL}/exercises/${id}`;
    const response = await axios.delete(endpoint);
    if(response.status === 200) {
      console.log("Exercise deleted successfully");
      return true;
    }
    console.log("Response from server is:", response.data);
    return false;
  } catch (error) {
    throw new Error(error.message);
  }
}

async function updateExercise(data) {
  try {
    const endpoint = `${baseURL}/exercises/${data.id}`;
    const response = await axios.put(endpoint, data);
    if(response.status === 200) {
      console.log("Exercise updated successfully");
      return true;
    }
    console.log("Response from server is:", response.data);
    return false;
  } catch (error) {
    throw new Error(error.message);
  }
}

export { createExercise, getExercises, deleteExercise,updateExercise};
