import React, { useState, useRef } from "react";
import {
  Container,
  Typography,
  Box,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { useLocation, useNavigate, Link } from "react-router-dom";

const EditExerciseImage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { exercise } = location.state;

  const [createUsing, setCreateUsing] = useState("images");
  const [steps, setSteps] = useState(exercise.steps);
  const [videoSrc, setVideoSrc] = useState(null);
  const [selectedStep, setSelectedStep] = useState(null);
  const [description, setDescription] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  const handleCreateUsingChange = (event) => {
    setCreateUsing(event.target.value);
    setVideoSrc(null); // Clear video source when changing the create method
  };

  const handleAddStep = () => {
    const total_steps = steps.length;
    setSteps([
      ...steps,
      {
        sequence_number: total_steps + 1,
        description: "",
        image: null,
        counts: null,
        countDuration: null,
      },
    ]);
  };

  const handleOpenDescriptionDialog = (sequence_number) => {
    const step = steps.find((s) => s.sequence_number === sequence_number);
    setSelectedStep(step);
    setDescription(step.description);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedStep(null);
    setDescription("");
  };

  const handleSaveDescription = () => {
    const updatedSteps = steps.map((step) =>
      step.sequence_number === selectedStep.sequence_number
        ? { ...step, description }
        : step
    );
    setSteps(updatedSteps);
    handleCloseDialog();
  };

  const handleCountsChange = (sequence_number, value) => {
    const updatedSteps = steps.map((step) =>
      step.sequence_number === sequence_number
        ? { ...step, counts: Number(value) }
        : step
    );
    setSteps(updatedSteps);
  };

  const handleCountDurationChange = (sequence_number, value) => {
    const updatedSteps = steps.map((step) =>
      step.sequence_number === sequence_number
        ? { ...step, countDuration: Number(value) }
        : step
    );
    setSteps(updatedSteps);
  };

  const handleVideoUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const url = URL.createObjectURL(file);
      setVideoSrc(url);
    }
  };

  const captureFrame = (sequence_number) => {
    if (videoRef.current && canvasRef.current) {
      const video = videoRef.current;
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");

      // Set the desired width and height
      const targetWidth = 640; // Example width
      const targetHeight = (video.videoHeight / video.videoWidth) * targetWidth;

      canvas.width = targetWidth;
      canvas.height = targetHeight;

      context.drawImage(video, 0, 0, targetWidth, targetHeight);

      canvas.toBlob(
        (blob) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            const base64Data = reader.result.split(",")[1];
            const newSteps = steps.map((step) =>
              step.sequence_number === sequence_number
                ? { ...step, image: base64Data }
                : step
            );
            setSteps(newSteps);
          };
          reader.readAsDataURL(blob);
        },
        "image/jpeg",
        0.9
      );
    }
  };

  const handleAddFile = (sequence_number, file) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");

        // Set the desired width and height
        const targetWidth = 640; // Example width
        const targetHeight = (img.height / img.width) * targetWidth;

        canvas.width = targetWidth;
        canvas.height = targetHeight;

        context.drawImage(img, 0, 0, targetWidth, targetHeight);

        canvas.toBlob(
          (blob) => {
            const reader = new FileReader();
            reader.onloadend = () => {
              const base64Data = reader.result.split(",")[1];
              const newSteps = steps.map((step) =>
                step.sequence_number === sequence_number
                  ? { ...step, image: base64Data }
                  : step
              );
              setSteps(newSteps);
            };
            reader.readAsDataURL(blob);
          },
          "image/jpeg",
          0.9
        );
      };
      img.src = reader.result;
    };
    reader.readAsDataURL(file);
  };

  const handleContinue = () => {
    if (steps.length < 1) {
      alert("Please add at least one step");
      return;
    }
    for (let i = 0; i < steps.length; i++) {
      if (
        steps[i].image === null ||
        steps[i].description === "" ||
        steps[i].description === null ||
        steps[i].counts === null ||
        steps[i].countDuration === null ||
        isNaN(steps[i].counts) ||
        isNaN(steps[i].countDuration)
      ) {
        alert(
          "Please add image, description, and counts and count duration for all steps"
        );
        return;
      }

      if(steps[i].counts < 1 || steps[i].countDuration < 1){
        alert("Counts and Count Duration should be greater than 0");
        return;
      
      }
    }
    console.log("Steps = ",steps);
    exercise.steps = steps;
    navigate("/exercise_edit_3", { state: { exercise } });
  };

  return (
    <Container sx={{ marginTop: 4, marginLeft: "auto", marginRight: "auto" }}>
      <Button
        variant="text"
        startIcon={<ArrowBackIcon />}
        sx={{
          marginBottom: 2,
          fontWeight: 700,
          fontStyle: "normal",
          fontFamily: "var(--Font-1)",
          color: "var(--Gray-700, #344054)",
        }}
        component={Link}
        to="/dashboard"
      >
        Back to Dashboard
      </Button>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          width: "80%",
          margin: "auto",
        }}
      >
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            fontFamily: "var(--Font-1)",
            fontWeight: 700,
            color: "var(--Gray-900, #101828)",
          }}
        >
          Edit Steps
        </Typography>
        <Typography variant="h6" gutterBottom>
          Edit Steps Using
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <RadioGroup value={createUsing} onChange={handleCreateUsingChange}>
            <FormControlLabel
              value="images"
              control={<Radio />}
              label="Images (3-4)"
              sx={{ color: "var(--Gray-700, #344054)" }}
            />
            <FormControlLabel
              value="video"
              control={<Radio />}
              label="Video"
              sx={{ color: "var(--Gray-700, #344054)" }}
            />
          </RadioGroup>

          {createUsing === "video" && (
            <>
              <Typography variant="h6" gutterBottom>
                Upload Video
              </Typography>
              <Button
                variant="outlined"
                component="label"
                startIcon={<UploadFileIcon />}
                sx={{
                  borderStyle: "dashed",
                  borderColor: "var(--Blue-light-500, #0BA5EC)",
                  color: "var(--Blue-light-500, #0BA5EC)",
                  textTransform: "none",
                }}
              >
                Click to upload or drag and drop
                <input
                  type="file"
                  accept="video/*"
                  hidden
                  onChange={handleVideoUpload}
                />
              </Button>
              {videoSrc && (
                <Box sx={{ marginTop: 2 }}>
                  <video
                    src={videoSrc}
                    controls
                    ref={videoRef}
                    style={{ width: "100%" }}
                  />
                  <canvas ref={canvasRef} style={{ display: "none" }} />
                </Box>
              )}
            </>
          )}

          {steps.map((step, index) => (
            <Box
              key={step.sequence_number}
              sx={{
                border: "1px solid lightgrey",
                padding: 2,
                marginBottom: 2,
                borderRadius: 1,
              }}
            >
              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  fontFamily: "var(--Font-1)",
                  fontWeight: 700,
                  color: "var(--Gray-900, #101828)",
                }}
              >
                Step {index + 1}:
              </Typography>
              {step.description && (
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: "var(--Font-1)",
                    color: "var(--Gray-700, #344054)",
                    marginBottom: 1,
                  }}
                >
                  {step.description}
                </Typography>
              )}
              <Box sx={{ display: "flex", gap: 2, alignContent: "center" }}>
                <Button
                  variant="text"
                  onClick={() =>
                    handleOpenDescriptionDialog(step.sequence_number)
                  }
                  sx={{
                    color: "grey",
                    textTransform: "none",
                    display: "flex",
                    alignItems: "center",
                  }}
                  startIcon={step.description ? <EditIcon /> : <AddIcon />}
                >
                  {step.description ? "Update Description" : "Add Description"}
                </Button>
                {createUsing === "video" ? (
                  <>
                    <Button
                      variant="contained"
                      sx={{
                        alignSelf: "center",
                        backgroundColor: "var(--Blue-light-500, #0BA5EC)",
                        color: "white",
                        textTransform: "none",
                        minWidth: "150px",
                        maxHeight: "36px",
                        textAlign: "center",
                        borderRadius: 1,
                      }}
                      onClick={() => {
                        captureFrame(step.sequence_number);
                      }}
                    >
                      Select Frame
                    </Button>
                    {step.image && (
                      <Box
                        component="img"
                        src={`data:image/png;base64,${step.image}`}
                        alt={`Step ${index + 1}`}
                        sx={{ width: "150px", height: "auto", borderRadius: 1 }}
                      />
                    )}
                  </>
                ) : (
                  <Button
                    variant="contained"
                    component="label"
                    sx={{
                      alignSelf: "center",
                      backgroundColor: "var(--Blue-light-500, #0BA5EC)",
                      color: "white",
                      textTransform: "none",
                      minWidth: "150px",
                      maxHeight: "36px",
                      textAlign: "center",
                      borderRadius: 1,
                    }}
                  >
                    Upload Image
                    <input
                      type="file"
                      hidden
                      onChange={(e) =>
                        handleAddFile(step.sequence_number, e.target.files[0])
                      }
                    />
                  </Button>
                )}
                {step.image && createUsing === "images" && (
                  <Box
                    component="img"
                    src={`data:image/png;base64,${step.image}`}
                    alt={`Step ${index + 1}`}
                    sx={{ width: "150px", height: "auto", borderRadius: 1 }}
                  />
                )}
                <TextField
                  label="Counts"
                  type="number"
                  value={step.counts}
                  onChange={(e) =>
                    handleCountsChange(step.sequence_number, e.target.value)
                  }
                  sx={{
                    maxWidth: "150px", // Increased width
                    marginLeft: 2,
                  }}
                />

                <TextField
                  label="Count Duration"
                  type="number"
                  value={step.countDuration}
                  onChange={(e) =>
                    handleCountDurationChange(
                      step.sequence_number,
                      e.target.value
                    )
                  }
                  sx={{
                    maxWidth: "150px", // Increased width
                    marginLeft: 2,
                  }}
                />
              </Box>
            </Box>
          ))}

          <Button
            variant="text"
            onClick={handleAddStep}
            sx={{
              color: "var(--Blue-light-500, #0BA5EC)",
              textTransform: "none",
              marginBottom: 2,
            }}
          >
            + Add Next Step
          </Button>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 2,
            marginBottom: 8,
          }}
        >
          <Button
            variant="outlined"
            component={Link}
            to="/manage-exercises"
            sx={{
              borderRadius: "8px",
              fontWeight: 700,
              color: "var(--Gray-700, #344054)",
              borderColor: "var(--Gray-700, #344054)",
            }}
          >
            Back
          </Button>
          <Button
            onClick={() => handleContinue()}
            variant="contained"
            sx={{
              background: "var(--Blue-light-500, #0BA5EC)",
              borderRadius: "8px",
              fontWeight: 700,
            }}
          >
            Continue
          </Button>
        </Box>
      </Box>

      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Add Description</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Description"
            type="text"
            fullWidth
            variant="outlined"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleSaveDescription} variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default EditExerciseImage;
