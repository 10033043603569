import React, { useState } from "react";
import {
  Container,
  Typography,
  Box,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Link,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const Login = ({ setIsAuthenticated }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();

  const handleLogin = () => {
    // Mock authentication logic
    // if (email  && password ) {
    //   setIsAuthenticated(true);
    //   navigate('/dashboard');
    // } else {
    //   alert('Invalid email or password');
    // }
    setIsAuthenticated(true);
    navigate("/dashboard");
  };

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: 400,
          textAlign: "center",
          marginBottom: 4,
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <img
          src="resolve_logo.png"
          alt="Logo"
          style={{ width: 60, height: 60, marginBottom: 16 }}
        />{" "}
        {/* Replace with actual logo path */}
        <Typography variant="h5" gutterBottom>
          Log in to your account
        </Typography>
        <Typography variant="body1" color="textSecondary" gutterBottom>
          Welcome back! Please enter your details.
        </Typography>
      </Box>
      <Box sx={{ width: "100%", maxWidth: 400 }}>
        <TextField
          label="Email"
          variant="outlined"
          fullWidth
          margin="normal"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          label="Password"
          variant="outlined"
          fullWidth
          type="password"
          margin="normal"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: 2,
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
              />
            }
            label="Remember for 30 days"
          />
          <Link href="/forgot_password" underline="hover" color="black">
            Forgot password
          </Link>
        </Box>
        <Button
          variant="contained"
          fullWidth
          sx={{
            mt: 3,
            mb: 2,
            background: "linear-gradient(90deg, #AD2B45 0%, #0084D1 100%)",
            color: "white",
          }}
          onClick={handleLogin}
        >
          Log In
        </Button>
        <Typography variant="body2" color="textSecondary" align="center">
          Don't have an account?{" "}
          <Link href="/signup" underline="hover" color="black">
            Sign up
          </Link>
        </Typography>
      </Box>
    </Container>
  );
};

export default Login;
