import React, { useState } from "react";
import {
  Container,
  Typography,
  Box,
  TextField,
  Button,
  Paper,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useNavigate, Link } from "react-router-dom";
import { styled } from "@mui/material/styles";

const AddExerciseOne = () => {
  const navigate = useNavigate();
  const [exerciseName, setExerciseName] = useState("");
  const [description, setDescription] = useState("");
  const [tags, setTags] = useState("");

  const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
  }));

  const handleContinue = () => {
    // Handle the continue action (e.g., form submission, navigation)
    if (!exerciseName || !description || !tags) {
      alert("Please fill all the fields");
      return;
    }
    const tagsArray = tags
      .trim()
      .split(",")
      .map((tag) => tag.trim());

    const data = {
      name: exerciseName,
      description: description,
      tags: tagsArray,
    };
    navigate("/add_exercise_2", { state: { data } });
  };

  return (
    <Container sx={{ marginTop: 4 }}>
      <Button
        variant="text"
        startIcon={<ArrowBackIosNewIcon />}
        sx={{
          marginBottom: 2,
          fontWeight: 700,
          fontStyle: "normal",
          fontFamily: "var(--Font-1)",
          color: "var(--Gray-700, #344054)",
        }}
        component={Link}
        to="/dashboard"
      >
        Back to Dashboard
      </Button>

      <StyledPaper elevation={3} sx={{ marginBottom: 4 }}>
        <Typography
          variant="h5"
          color="red"
          gutterBottom
          sx={{ fontWeight: 500 }}
        >
          Instructions:
        </Typography>
        <Box sx={{ color: "#777", marginBottom: 2 }}>
          <Typography variant="body1" color="red">
            Follow these instructions carefully :
          </Typography>
          <ul>
            <li>
              Full body should be visible in the camera while doing the
              exercise.
            </li>
            <li>Stand in a bright illuminated room for accurate detection.</li>
            <li>
              Black clothes are prefered in the images for better detection.
            </li>
            <li>
              Taking images/videos in landscape mode is recommended for better detection.
            </li>
          </ul>
        </Box>
      </StyledPaper>

      <Box
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          width: "80%",
          margin: "auto",
          padding: 4,
        }}
      >
        <Typography
          sx={{
            fontFamily: "var(--Font-1)",
            fontWeight: 700,
            color: "#101828",
          }}
          variant="h4"
          gutterBottom
        >
          Add New Exercise
        </Typography>
        <Typography variant="h6">Exercise Name</Typography>
        <TextField
          variant="outlined"
          placeholder="Enter exercise name"
          value={exerciseName}
          onChange={(e) => setExerciseName(e.target.value)}
          InputProps={{ sx: { borderRadius: 2 } }}
        />
        <Typography variant="h6">Description</Typography>
        <TextField
          variant="outlined"
          placeholder="Enter description about exercise..."
          multiline
          rows={4}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          InputProps={{ sx: { borderRadius: 2 } }}
        />
        <Typography variant="h6">Category/Tags</Typography>
        <TextField
          variant="outlined"
          placeholder="Add tags separated by commas (,) "
          value={tags}
          onChange={(e) => setTags(e.target.value)}
          InputProps={{ sx: { borderRadius: 2 } }}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 2,
            marginBottom: 8,
          }}
        >
          <Button
            variant="outlined"
            sx={{
              marginRight: 1,
              borderRadius: "8px",
              height: 50,
              width: "280px",
              fontWeight: 700,
              color: "var(--Gray-700, #344054)",
              borderColor: "var(--Gray-700, #344054)",
            }}
          >
            Back
          </Button>
          <Button
            onClick={handleContinue}
            variant="contained"
            sx={{
              background: "var(--Blue-light-500, #0BA5EC)",
              borderRadius: "8px",
              height: 50,
              width: "280px",
              fontWeight: 700,
            }}
          >
            Continue
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default AddExerciseOne;
