import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Typography,
  Box,
  Paper,
  Grid,
  Button,
  // IconButton,
  Chip,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
// import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
// import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useLocation, Link, useNavigate } from "react-router-dom";
import {
  PoseLandmarker,
  FilesetResolver,
  DrawingUtils,
} from "https://cdn.skypack.dev/@mediapipe/tasks-vision@0.10.0";
import { v4 as uuidv4 } from "uuid";
import { createExercise } from "../../../utils/ApiHelpers/exercise_api_helper";

const PoseDetection = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { data, steps } = location.state;
  const [poseLandmarker, setPoseLandmarker] = useState(null);
  const [isDetecting, setIsDetecting] = useState(true);
  const isMounted = useRef(true);

  useEffect(() => {
    isMounted.current = true;
    const createPoseLandmarker = async () => {
      const vision = await FilesetResolver.forVisionTasks(
        "https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.0/wasm"
      );
      const poseLandmarker = await PoseLandmarker.createFromOptions(vision, {
        baseOptions: {
          modelAssetPath: `https://storage.googleapis.com/mediapipe-models/pose_landmarker/pose_landmarker_lite/float16/1/pose_landmarker_lite.task`,
          delegate: "GPU",
        },
        runningMode: "IMAGE",
        numPoses: 2,
      });
      if (isMounted.current) {
        setPoseLandmarker(poseLandmarker);
      }
    };
    createPoseLandmarker();
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const detectPoses = async () => {
      if (poseLandmarker) {
        for (const step of steps) {
          if (!isMounted.current) break;
          const image = new Image();
          image.src = `data:image/png;base64,${step.image}`;
          await new Promise((resolve) => {
            image.onload = async () => {
              const canvas = document.getElementById(
                `canvas-${step.sequence_number}`
              );
              if (!isMounted.current || !canvas) {
                resolve();
                return;
              }
              const canvasCtx = canvas.getContext("2d");
              if (!canvasCtx) {
                resolve();
                return;
              }
              canvas.width = image.naturalWidth;
              canvas.height = image.naturalHeight;
              canvasCtx.clearRect(0, 0, canvas.width, canvas.height);
              canvasCtx.drawImage(image, 0, 0, canvas.width, canvas.height);

              const result = await poseLandmarker.detect(image);
              if (result.landmarks.length > 0) {
                // add poselandmarker data to the steps
                console.log("Landmarks", result);
                step["landmarks"] = result.landmarks[0];
                step["worldLandmarks"] = result.worldLandmarks[0];

                const drawingUtils = new DrawingUtils(canvasCtx);
                result.landmarks.forEach((landmarks) => {
                  drawingUtils.drawLandmarks(landmarks, {
                    radius: (data) =>
                      DrawingUtils.lerp(
                        data.from && data.from.z ? data.from.z : 0,
                        -0.15,
                        0.1,
                        5,
                        1
                      ),
                  });
                  drawingUtils.drawConnectors(
                    landmarks,
                    PoseLandmarker.POSE_CONNECTIONS
                  );
                });
              }
              resolve();
            };
          });
        }
        setIsDetecting(false);
      }
    };
    detectPoses();
  }, [poseLandmarker, steps]);

  const handleAddExercise = async () => {
    data["steps"] = steps;
    data["id"] = uuidv4();
    for (let i = 0; i < data.steps.length; i++) {
      data.steps[i]["exercise_id"] = data.id;
    }
    console.log("Final Data for Exercise", data);

    try {
      const res = await createExercise(data);
      if (res) {
        console.log("Exercise created successfully");
        alert("Exercise created successfully");
        navigate("/manage-exercises");
      }
    } catch (error) {
      console.log("Error while creating exercise", error);
      alert("Error while creating exercise");
    }
  };

  return (
    <Container sx={{ marginTop: 4 }}>
      <Button
        variant="text"
        startIcon={
          <ArrowBackIosNewIcon
            sx={{
              width: "17px",
              height: "17px",
              color: "var(--Gray-900, #101828)",
            }}
          />
        }
        sx={{
          marginBottom: 2,
          fontWeight: 700,
          fontStyle: "normal",
          fontFamily: "var(--Font-1)",
          color: "var(--Gray-900, #101828)",
        }}
        component={Link}
        to="/dashboard"
        disabled={isDetecting} // Disable the button until detection is complete
      >
        <Typography
          sx={{
            fontSize: "13px",
            fontStyle: "normal",
            fontFamily: "var(--Font-1)",
            color: "var(--Gray-900, #101828)",
            fontWeight: 700,
          }}
        >
          Back to Dashboard
        </Typography>
      </Button>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 2,
        }}
      >
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            fontFamily: "var(--Font-1)",
            fontWeight: 700,
            color: "var(--Gray-900, #101828)",
            paddingRight: 113,
          }}
        >
          Pose Detection
        </Typography>

        {/* <Box
          sx={{
            display: "flex",
            gap: 1,
            border: "1px solid #e0e0e0",
            borderRadius: 2,
          }}
        >
          <IconButton disabled={isDetecting}>
            <EditOutlinedIcon />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: 1,
            border: "1px solid #e0e0e0",
            borderRadius: 2,
          }}
        >
          <IconButton disabled={isDetecting}>
            <MoreVertIcon />
          </IconButton>
        </Box> */}
      </Box>

      <Typography
        variant="body1"
        sx={{
          marginBottom: 2,
          fontFamily: "var(--Font-1)",
          color: "var(--Gray-700, #344054)",
        }}
      >
        Pose detection is used to identify the coordinates of key points on a
        person's body in an image or video.
      </Typography>

      <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap", marginBottom: 4 }}>
        {data.tags.map((tag, tagIndex) => (
          <Chip
            key={tagIndex}
            label={tag}
            variant="outlined"
            sx={{
              fontWeight: 700,
              color: "#175CD3",
              borderColor: "#B2DDFF",
              backgroundColor: "#EFF8FF",
            }}
          />
        ))}
      </Box>

      <Grid container spacing={2}>
        {steps.map((step) => (
          <Grid item md={4} key={step.sequence_number}>
            <Paper
              sx={{
                borderRadius: 3,
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{ position: "relative", width: "100%", height: "200px" }}
              >
                <img
                  src={`data:image/png;base64,${step.image}`}
                  alt={step.title}
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
                <canvas
                  id={`canvas-${step.sequence_number}`}
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    pointerEvents: "none",
                  }}
                />
              </Box>
              <Box sx={{ padding: 2, flexGrow: 1 }}>
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{
                    fontFamily: "var(--Font-1)",
                    fontWeight: 700,
                    color: "var(--Gray-900, #101828)",
                  }}
                >
                  {step.title}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: "var(--Font-1)",
                    color: "var(--Gray-700, #344054)",
                    overflowWrap: "break-word", // Ensure text wraps
                  }}
                >
                  {step.description}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: "var(--Font-1)",
                    color: "var(--Gray-700, #344054)",
                    marginTop: 1,
                  }}
                >
                  Counts: {step.counts}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: "var(--Font-1)",
                    color: "var(--Gray-700, #344054)",
                    marginTop: 1,
                  }}
                >
                  Step Duration: {step.countDuration} seconds
                </Typography>
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: 2,
          marginBottom: 8,
        }}
      >
        <Button
          variant="outlined"
          component={Link}
          to="/manage-exercises"
          sx={{
            borderRadius: "8px",
            fontWeight: 700,
            color: "var(--Gray-700, #344054)",
            borderColor: "var(--Gray-700, #344054)",
          }}
          disabled={isDetecting} // Disable the button until detection is complete
        >
          Back
        </Button>
        <Button
          onClick={() => handleAddExercise()}
          variant="contained"
          sx={{
            background: "var(--Blue-light-500, #0BA5EC)",
            borderRadius: "8px",
            fontWeight: 700,
          }}
          disabled={isDetecting} // Disable the button until detection is complete
        >
          Add Exercise
        </Button>
      </Box>
    </Container>
  );
};

export default PoseDetection;
