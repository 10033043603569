import React, { useState } from 'react';
import { Container, Typography, Box, TextField, Button, Checkbox, FormControlLabel, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const SignUp = ({ setIsAuthenticated }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();

  const handleSignUp = () => {
    // Mock sign-up logic
    if (name && email && password) {
      setIsAuthenticated(true);
      navigate('/dashboard');
    } else {
      alert('Please fill all fields');
    }
  };

  return (
    <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
      <Box sx={{ width: '100%', maxWidth: 400, textAlign: 'center', marginBottom: 4 ,
          marginLeft: "auto",
          marginRight: "auto",}}>
        <img src="resolve_logo.png" alt="Logo" style={{ width: 60,height:60, marginBottom: 16 }} /> {/* Replace with actual logo path */}
        <Typography variant="h5" gutterBottom>
          Create your account
        </Typography>
        <Typography variant="body1" color="textSecondary" gutterBottom>
          Welcome! Please enter your details.
        </Typography>
      </Box>
      <Box sx={{ width: '100%', maxWidth: 400 }}>
        <TextField
          label="Name"
          variant="outlined"
          fullWidth
          margin="normal"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          label="Email"
          variant="outlined"
          fullWidth
          margin="normal"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          label="Password"
          variant="outlined"
          fullWidth
          type="password"
          margin="normal"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 2 }}>
          <FormControlLabel
            control={<Checkbox checked={rememberMe} onChange={(e) => setRememberMe(e.target.checked)} />}
            label="Remember for 30 days"
          />
          <Link href="#" underline="hover" color="black">
            Forgot password
          </Link>
        </Box>
        <Button
          variant="contained"
          fullWidth
          sx={{ mt: 3, mb: 2, background: 'linear-gradient(90deg, #AD2B45 0%, #0084D1 100%)', color: 'white' }}
          onClick={handleSignUp}
        >
          Sign Up
        </Button>
        <Typography variant="body2" color="textSecondary" align="center">
          Already have an account? <Link href="/login" underline="hover" color="black">Log in</Link>
        </Typography>
      </Box>
    </Container>
  );
};

export default SignUp;
