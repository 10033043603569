import React, { useState } from "react";
import {
  Container,
  Typography,
  Box,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Pagination,
  TableFooter,
  Menu,
  MenuItem,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Link } from "react-router-dom";
import DeleteEntityDialog from "../../components/delete_entity_dialog";

const exercises = [
  {
    id: 1,
    name: "Side Stretching",
    time: "5pm - 6pm",
    date: "25 August 2024",
    status: "Completed",
  },
  {
    id: 2,
    name: "Side Stretching",
    time: "5pm - 6pm",
    date: "25 August 2024",
    status: "Completed",
  },
  {
    id: 3,
    name: "Side Stretching",
    time: "5pm - 6pm",
    date: "25 August 2024",
    status: "Completed",
  },
  {
    id: 4,
    name: "Side Stretching",
    time: "5pm - 6pm",
    date: "25 August 2024",
    status: "Completed",
  },
  {
    id: 5,
    name: "Side Stretching",
    time: "5pm - 6pm",
    date: "25 August 2024",
    status: "Completed",
  },
  // Add more exercises as needed
];

const ManageSessions = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorE2, setAnchorE2] = React.useState(null);

  const open1 = Boolean(anchorEl);
  const open2 = Boolean(anchorE2);

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [selectedSession, setSelectedSession] = useState(null);

  const handleMenuClick1 = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClick2 = (event) => {
    setAnchorE2(event.currentTarget);
  };

  const handleMenuClose1 = () => {
    setAnchorEl(null);
  };
  const handleMenuClose2 = () => {
    setAnchorE2(null);
  };

  const handleDeleteClick = (session) => {
    setSelectedSession(session);
    setIsDeleteDialogOpen(true);
    setAnchorE2(null);
  };

  const handleDeleteConfirm = () => {
    // Perform the delete action
    console.log("Deleted session:", selectedSession);
    setIsDeleteDialogOpen(false);
  };

  return (
    <Container sx={{ marginTop: 4 }}>
      <Button
        variant="text"
        startIcon={<ArrowBackIosNewIcon sx={{width:"17px",
        height:"17px",color: "var(--Gray-900, #101828)"}}/>}
        sx={{
          marginBottom: 2,
          fontWeight: 700,
          fontStyle: "normal",
          fontFamily: "var(--Font-1)",
          color: "var(--Gray-900, #101828)",
        }}
        component={Link}
        to="/dashboard"
      >
        <Typography sx={{ 
          fontSize: "13px",
          fontStyle: "normal",
          fontFamily: "var(--Font-1)",
          color: "var(--Gray-900, #101828)",
          fontWeight: 700, 
          }}>Back to Dashboard</Typography>
      </Button>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 2,
        }}
      >
        <Typography
          sx={{
            fontFamily: "var(--Font-1)",
            fontWeight: 700,
            color: "var(--Gray-900, #101828)",
          }}
          variant="h4"
          gutterBottom
        >
          Manage Sessions
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 2,
        }}
      >
        <TextField
          label="Search"
          variant="outlined"
          sx={{ flexGrow: 1, marginRight: 2 }}
        />
        <Button
          variant="outlined"
          sx={{
            marginRight: 1,
            borderRadius: "8px",
            height: 50,
            width: 90,
            fontWeight: 700,
            color: "var(--Gray-700, #344054)",
            borderColor: "var(--Gray-700, #344054)",
          }}
        >
          Clear
        </Button>
        <Button
          variant="contained"
          sx={{
            background: "var(--Blue-light-500, #0BA5EC)",
            borderRadius: "8px",
            height: 50,
            width: 90,
            fontWeight: 700,
          }}
        >
          Search
        </Button>
      </Box>
      {/* 
      
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 2,
        }}
      >
        <Typography variant="h6">All Sessions</Typography>
        <IconButton onClick={handleMenuClick1}>
          <MoreVertIcon />
        </IconButton>
        <Menu anchorEl={anchorEl} open={open1} onClose={handleMenuClose1}>
          <MenuItem onClick={handleMenuClose1}>Option 1</MenuItem>
          <MenuItem onClick={handleMenuClose1}>Option 2</MenuItem>
          <MenuItem onClick={handleMenuClose1}>Option 3</MenuItem>
        </Menu>
      </Box> */}

      <TableContainer component={Paper} sx={{ marginBottom: 8 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={6}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 2,
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      variant="h6"
                      sx={{
                        marginRight: 2,
                        fontFamily: "var(--Font-1)",
                        fontWeight: 700,
                        color: "var(--Gray-700, #344054)",
                      }}
                    >
                      All Sessions
                    </Typography>
                  </Box>

                  <IconButton onClick={handleMenuClick1}>
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={open1}
                    onClose={handleMenuClose1}
                  >
                    <MenuItem onClick={handleMenuClose1}>Option 1</MenuItem>
                    <MenuItem onClick={handleMenuClose1}>Option 2</MenuItem>
                    <MenuItem onClick={handleMenuClose1}>Option 3</MenuItem>
                  </Menu>
                </Box>
              </TableCell>
            </TableRow>
          </TableHead>

          {/* <TableHead>
            <TableRow>
              <TableCell>Sr. Num.</TableCell>
              <TableCell>Patient Name</TableCell>
              <TableCell>Time</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead> */}

          <TableHead>
            <TableRow>
              <TableCell>
                <Typography
                  sx={{ fontWeight: 700, color: "var(--Gray-600, #475467)" }}
                >
                  Sr. Num.
                </Typography>
              </TableCell>
              <TableCell sx={{ paddingRight: "400px" }}>
                <Typography
                  sx={{ fontWeight: 700, color: "var(--Gray-600, #475467)" }}
                >
                  Patient Name
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  sx={{ fontWeight: 700, color: "var(--Gray-600, #475467)" }}
                >
                  Time
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  sx={{ fontWeight: 700, color: "var(--Gray-600, #475467)" }}
                >
                  Date
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  sx={{ fontWeight: 700, color: "var(--Gray-600, #475467)" }}
                >
                  Status
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {exercises.map((exercise, index) => (
              <TableRow
                key={exercise.id}
                sx={{ backgroundColor: index % 2 === 0 ? "#f9f9f9" : "white" }}
              >
                {/* <TableCell>{index + 1}</TableCell>
                <TableCell>{exercise.name}</TableCell>
                <TableCell>{exercise.time}</TableCell>
                <TableCell>{exercise.date}</TableCell>
                <TableCell>{exercise.status}</TableCell> */}

                <TableCell>
                  <Typography
                    sx={{ fontWeight: 700, color: "var(--Gray-600, #475467)" }}
                  >
                    {index + 1}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    sx={{ fontWeight: 700, color: "var(--Gray-600, #475467)" }}
                  >
                    {exercise.name}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={{ color: "var(--Gray-600, #475467)" }}>
                    {exercise.time}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={{ color: "var(--Gray-600, #475467)" }}>
                    {exercise.date}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={{ color: "var(--Gray-600, #475467)" }}>
                    {exercise.status}
                  </Typography>
                </TableCell>

                <TableCell align="right">
                  {/* <IconButton color="black">
                    <EditOutlinedIcon />
                  </IconButton>
                  <IconButton color="black">
                    <DeleteOutlinedIcon />
                  </IconButton> */}
                  <IconButton onClick={handleMenuClick2}>
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    anchorEl={anchorE2}
                    open={open2}
                    onClose={handleMenuClose2}
                  >
                    <MenuItem onClick={handleMenuClose2}>View Session</MenuItem>
                    <MenuItem onClick={handleMenuClose2}>
                      Session Report
                    </MenuItem>
                    <MenuItem onClick={handleDeleteClick}>Delete</MenuItem>
                  </Menu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={6}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    color: "black",
                    borderColor: "black",
                  }}
                >
                  <Button
                    sx={{
                      marginRight: 1,
                      borderRadius: "8px",
                      fontWeight: 700,
                      color: "var(--Gray-700, #344054)",
                      borderColor: "var(--Gray-700, #344054)",
                    }}
                    variant="outlined"
                    startIcon={<ArrowBackIosNewIcon />}
                  >
                    Previous
                  </Button>
                  <Pagination count={10} variant="outlined" shape="rounded" />
                  <Button
                    sx={{
                      marginLeft: 10,
                      borderRadius: "8px",
                      fontWeight: 700,
                      color: "var(--Gray-700, #344054)",
                      borderColor: "var(--Gray-700, #344054)",
                    }}
                    variant="outlined"
                    endIcon={<ArrowForwardIosIcon />}
                  >
                    Next
                  </Button>
                </Box>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <DeleteEntityDialog
        open={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        onDelete={handleDeleteConfirm}
        entity="Session"
      />
    </Container>
  );
};

export default ManageSessions;
