// src/App.js

import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Navbar from "./components/nav_bar";
import DashBoard from "./pages/dashboard";
import Login from "./pages/Auth/login";
import Signup from "./pages/Auth/sign_up";
import ManageExercises from "./pages/Exercises/manage_exercises";
import ManageUsers from "./pages/Users/manage_users";
import ManageSessions from "./pages/Sessions/manage_sessions";
import AddExerciseOne from "./pages/Exercises/add_exercise/add_exercise_1";
import AddExerciseTwo from "./pages/Exercises/add_exercise/add_exercise_2";
import ExerciseDetails from "./pages/Exercises/exercise_details";
import ForgotPassword from "./pages/Auth/ForgotPassword/forgot_password";
import CheckEmail from "./pages/Auth/ForgotPassword/check_email";
import SetNewPassword from "./pages/Auth/ForgotPassword/set_new_password";
import AllDone from "./pages/Auth/ForgotPassword/all_done";
import PoseDetection from "./pages/Exercises/add_exercise/pose_detection";
import EditExercise from "./pages/Exercises/edit_exercise/edit_exercise_1";
import EditExerciseImage from "./pages/Exercises/edit_exercise/edit_exercise_2";
import EditExerciseConfirm from "./pages/Exercises/edit_exercise/edit_exercise_3";

const App = () => {
  //get token from local storage and id exist, set get as true
  const get = true;
  const [isAuthenticated, setIsAuthenticated] = useState(get);

  return (
    <Router>
      {isAuthenticated && <Navbar />}
      <Routes>
        {!isAuthenticated ? (
          <>
            <Route
              path="/login"
              element={<Login setIsAuthenticated={setIsAuthenticated} />}
            />
            <Route
              path="/signup"
              element={<Signup setIsAuthenticated={setIsAuthenticated} />}
            />
            <Route path="/forgot_password" element={<ForgotPassword />} />
            <Route path="/check_email" element={<CheckEmail />} />
            <Route path="/set_new_password" element={<SetNewPassword />} />
            <Route path="/all_done" element={<AllDone />} />
            <Route path="*" element={<Navigate to="/login" />} />
          </>
        ) : (
          <>
            <Route path="/dashboard" element={<DashBoard />} />
            <Route path="/manage_exercises" element={<ManageExercises />} />
            <Route path="/manage_users" element={<ManageUsers />} />
            <Route path="/manage_sessions" element={<ManageSessions />} />
            <Route path="/add_exercise_1" element={<AddExerciseOne />} />
            <Route path="/add_exercise_2" element={<AddExerciseTwo />} />
            <Route path="/exercise_details" element={<ExerciseDetails />} />
            <Route path="/exercise_edit_1" element={<EditExercise />} />
            <Route path="/exercise_edit_2" element={<EditExerciseImage />} />
            <Route path="/exercise_edit_3" element={<EditExerciseConfirm />} />
            <Route path="/pose_detection" element={<PoseDetection />} />
            <Route path="*" element={<Navigate to="/dashboard" />} />
          </>
        )}
      </Routes>
    </Router>
  );
};

export default App;
