import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Box,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Pagination,
  TableFooter,
  Menu,
  MenuItem,
  Chip,
  CircularProgress,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate, Link } from "react-router-dom";
import DeleteEntityDialog from "../../components/delete_entity_dialog";

import {
  getExercises,
  deleteExercise,
} from "../../utils/ApiHelpers/exercise_api_helper";

const ManageExercises = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [isDeleteDilaogoOpen, setIsDeleteDilaogoOpen] = useState(false);
  const [selectedExercise, setSelectedExercise] = useState(null);
  const [exercises, setExercises] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchExercises = async () => {
      setIsLoading(true);
      // Fetch exercises from the server here
      const data = await getExercises();
      const formattedExercises = data.map((exercise) => ({
        id: exercise.id,
        name: exercise.name,
        tags: exercise.tags,
        description: exercise.description,
        steps: exercise.steps,
      }));
      setExercises(formattedExercises);
      setIsLoading(false);
    };
    fetchExercises();
  }, []);

  // Calculate the exercises to display on the current page
  const indexOfLastExercise = currentPage * itemsPerPage;
  const indexOfFirstExercise = indexOfLastExercise - itemsPerPage;
  const filteredExercises = exercises.filter((exercise) =>
    exercise.name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const currentExercises = filteredExercises.slice(
    indexOfFirstExercise,
    indexOfLastExercise
  );

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteClick = (exercise) => {
    setIsDeleteDilaogoOpen(true);
    setSelectedExercise(exercise);
  };

  const handleDeleteConfirm = async () => {
    // Add delete logic here
    const id = selectedExercise.id;
    const response = await deleteExercise(id);
    if (response === true) {
      alert("Exercise deleted successfully");
      setExercises((prev) =>
        prev.filter((exercise) => exercise.id !== selectedExercise.id)
      );
    } else {
      alert("Failed to delete exercise");
    }
    setIsDeleteDilaogoOpen(false);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

  const handleSearchClear = () => {
    setSearchQuery("");
    setCurrentPage(1);
  };

  return (
    <Container sx={{ marginTop: 4 }}>
      <Button
        variant="text"
        startIcon={
          <ArrowBackIosNewIcon
            sx={{
              width: "17px",
              height: "17px",
              color: "var(--Gray-900, #101828)",
            }}
          />
        }
        sx={{
          marginBottom: 2,
          fontWeight: 700,
          fontStyle: "normal",
          fontFamily: "var(--Font-1)",
          color: "var(--Gray-900, #101828)",
        }}
        component={Link}
        to="/dashboard"
      >
        <Typography
          sx={{
            fontSize: "13px",
            fontStyle: "normal",
            fontFamily: "var(--Font-1)",
            color: "var(--Gray-900, #101828)",
            fontWeight: 700,
          }}
        >
          Back to Dashboard
        </Typography>
      </Button>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 2,
        }}
      >
        <Typography
          sx={{
            fontFamily: "var(--Font-1)",
            fontWeight: 700,
            color: "var(--Gray-900, #101828)",
          }}
          variant="h4"
          gutterBottom
        >
          Manage Exercises
        </Typography>
        <Button
          onClick={() => navigate("/add_exercise_1")}
          variant="contained"
          sx={{
            background: "var(--Blue-light-500, #0BA5EC)",
            borderRadius: "8px",
            fontWeight: 600,
          }}
          startIcon={<AddIcon />}
        >
          Add Exercise
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 2,
        }}
      >
        <TextField
          label="Search"
          variant="outlined"
          value={searchQuery}
          onChange={handleSearchChange}
          sx={{ flexGrow: 1, marginRight: 2 }}
        />
        <Button
          variant="outlined"
          sx={{
            marginRight: 1,
            borderRadius: "8px",
            height: 50,
            width: 90,
            fontWeight: 700,
            color: "var(--Gray-700, #344054)",
            borderColor: "var(--Gray-700, #344054)",
          }}
          onClick={handleSearchClear}
        >
          Clear
        </Button>
        <Button
          variant="contained"
          sx={{
            background: "var(--Blue-light-500, #0BA5EC)",
            borderRadius: "8px",
            height: 50,
            width: 90,
            fontWeight: 700,
          }}
        >
          Search
        </Button>
      </Box>

      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer component={Paper} sx={{ marginBottom: 8 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell colSpan={6}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 2,
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography
                        variant="h6"
                        sx={{
                          marginRight: 2,
                          fontFamily: "var(--Font-1)",
                          fontWeight: 700,
                          color: "var(--Gray-700, #344054)",
                        }}
                      >
                        All Exercises
                      </Typography>
                    </Box>
                    <IconButton onClick={handleMenuClick}>
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleMenuClose}
                    >
                      <MenuItem onClick={handleMenuClose}>Option 1</MenuItem>
                      <MenuItem onClick={handleMenuClose}>Option 2</MenuItem>
                      <MenuItem onClick={handleMenuClose}>Option 3</MenuItem>
                    </Menu>
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography
                    sx={{ fontWeight: 700, color: "var(--Gray-600, #475467)" }}
                  >
                    Sr. Num.
                  </Typography>
                </TableCell>

                <TableCell sx={{ paddingRight: "400px" }}>
                  <Typography
                    sx={{ fontWeight: 700, color: "var(--Gray-600, #475467)" }}
                  >
                    Exercise Name
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    sx={{ fontWeight: 700, color: "var(--Gray-600, #475467)" }}
                  >
                    Category/Tags
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {currentExercises.map((exercise, index) => (
                <TableRow
                  key={exercise.id}
                  sx={{ backgroundColor: index % 2 === 0 ? "#f9f9f9" : "white" }}
                >
                  <TableCell>
                    <Typography
                      sx={{ fontWeight: 700, color: "var(--Gray-600, #475467)" }}
                    >
                      {indexOfFirstExercise + index + 1}
                    </Typography>
                  </TableCell>

                  <TableCell
                    onClick={() => {
                      console.log(exercise);
                      navigate("/exercise_details", { state: { exercise } });
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <Box
                        component="img"
                        src="avatar.png" // Replace with the actual path to the exercise image
                        alt={exercise.name}
                        sx={{ width: 40, height: 40, marginRight: 2 }}
                      />
                      <Typography
                        sx={{
                          fontWeight: 700,
                          color: "var(--Gray-600, #475467)",
                        }}
                      >
                        {exercise.name}
                      </Typography>
                    </Box>
                  </TableCell>

                  <TableCell>
                    <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
                      {exercise.tags.slice(0, 3).map((tag, tagIndex) => (
                        <Chip
                          key={tagIndex}
                          label={tag}
                          variant="outlined"
                          sx={{
                            fontWeight: 700,
                            color:
                              tagIndex === 0
                                ? "#6941C6"
                                : tagIndex === 1
                                ? "#175CD3"
                                : "#3538CD",
                            borderColor:
                              tagIndex === 0
                                ? "#E9D7FE"
                                : tagIndex === 1
                                ? "#B2DDFF"
                                : "#C7D7FE",
                            backgroundColor:
                              tagIndex === 0
                                ? "#F9F5FF"
                                : tagIndex === 1
                                ? "#EFF8FF"
                                : "#EEF4FF",
                          }}
                        />
                      ))}
                      {exercise.tags.length > 3 && (
                        <Chip
                          label={`+${exercise.tags.length - 3}`}
                          variant="outlined"
                          sx={{
                            fontWeight: 700,
                            color: "var(--Gray-600, #475467)",
                            borderColor: "#1976d2",
                          }}
                        />
                      )}
                    </Box>
                  </TableCell>

                  <TableCell align="right">
                    <IconButton
                      onClick={() => handleDeleteClick(exercise)}
                      color="black"
                    >
                      <DeleteOutlinedIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        navigate("/exercise_edit_1", {
                          state: { exercise },
                        });
                      }}
                      color="black"
                    >
                      <EditOutlinedIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={6}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      color: "black",
                      borderColor: "black",
                    }}
                  >
                    <Button
                      sx={{
                        marginRight: 1,
                        borderRadius: "8px",
                        fontWeight: 700,
                        color: "var(--Gray-700, #344054)",
                        borderColor: "var(--Gray-700, #344054)",
                      }}
                      variant="outlined"
                      startIcon={<ArrowBackIosNewIcon />}
                      onClick={() =>
                        setCurrentPage((prev) => Math.max(prev - 1, 1))
                      }
                    >
                      Previous
                    </Button>
                    <Pagination
                      count={Math.ceil(
                        filteredExercises.length / itemsPerPage
                      )}
                      page={currentPage}
                      onChange={handlePageChange}
                      variant="outlined"
                      shape="rounded"
                    />
                    <Button
                      sx={{
                        marginLeft: 10,
                        borderRadius: "8px",
                        fontWeight: 700,
                        color: "var(--Gray-700, #344054)",
                        borderColor: "var(--Gray-700, #344054)",
                      }}
                      variant="outlined"
                      endIcon={<ArrowForwardIosIcon />}
                      onClick={() =>
                        setCurrentPage((prev) =>
                          Math.min(
                            prev + 1,
                            Math.ceil(
                              filteredExercises.length / itemsPerPage
                            )
                          )
                        )
                      }
                    >
                      Next
                    </Button>
                  </Box>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      )}
      <DeleteEntityDialog
        open={isDeleteDilaogoOpen}
        onClose={() => setIsDeleteDilaogoOpen(false)}
        onDelete={handleDeleteConfirm}
        entity="Exercise"
      />
    </Container>
  );
};

export default ManageExercises;
