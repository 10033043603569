import React from "react";
import {
  Container,
  Typography,
  Box,
  Button
} from "@mui/material";
import { useNavigate } from "react-router-dom";


const AllDone = () => {
    const navigate = useNavigate();
  return (
    <Container sx={{ marginTop: 8 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          maxWidth: "400px",
          margin: "auto",
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: 400,
            textAlign: "center",
            marginBottom: 4,
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <img
            src="resolve_logo.png"
            alt="Logo"
            style={{ width: 60, height: 60, marginBottom: 16 }}
          />
        </Box>
        <Typography
          variant="h4"
          sx={{
            fontWeight: 600,
            fontStyle: "normal",
            fontFamily: "var(--Font-1)",
            color: "var(--Gray-900, #101828)",
            marginBottom: "16px",
            fontSize: "30px",
          }}
        >
          All done!
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontFamily: "var(--Font-1)",
            color: "var(--Gray-700, #344054)",
            textAlign: "center",
            marginBottom: "32px",
          }}
        >
          Your password has been successfully reset.
        </Typography>
        <Button
        onClick={() => {navigate("/login")}}
          variant="contained"
          fullWidth
          sx={{
            background: "linear-gradient(90deg, #AD2B45 0%, #0084D1 100%)",
            borderRadius: "8px",
            height: "50px",
            fontWeight: 700,
            textTransform: "none",
            marginBottom: "16px",
          }}
        >
          Continue
        </Button>
      </Box>
    </Container>
  );
};

export default AllDone;
