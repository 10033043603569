import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

const DeleteEntityDialog = ({ open, onClose, onDelete, entity }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 3,
          width: "400px", // Increase the width as needed
        },
      }}
    >
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <DeleteOutlineIcon
            sx={{
              width: 24,
              height: 24,
              marginRight: 1,
              color: "#D32F2F",
              backgroundColor: "#FCE8E6",
              borderRadius: "50%",
              padding: 0.5,
            }}
          />
          <Typography
            variant="h6"
            sx={{
              fontWeight: 700,
              fontFamily: "Poppins, sans-serif",
              color: "var(--Gray-900, #101828)",
              flexGrow: 1,
            }}
          >
            Delete {entity}
          </Typography>
          <IconButton onClick={onClose} sx={{ padding: 0 }}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography
          sx={{
            fontFamily: "Poppins, sans-serif",
            color: "var(--Gray-700, #344054)",
            marginBottom: 2,
          }}
        >
          Are you sure you want to delete this {entity}?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          sx={{
            fontFamily: "Poppins, sans-serif",
            color: "var(--Gray-700, #344054)",
            borderColor: "var(--Gray-700, #344054)",
            borderRadius: "8px",
            textTransform: "none",
            fontWeight: 600,
          }}
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          onClick={onDelete}
          sx={{
            background: "#D32F2F",
            color: "#FFFFFF",
            borderRadius: "8px",
            fontFamily: "Poppins, sans-serif",
            textTransform: "none",
          }}
          variant="contained"
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteEntityDialog;
