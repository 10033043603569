import React, { useState } from "react";
import { Container, Typography, Box, TextField, Button } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useNavigate, Link,useLocation } from "react-router-dom";

const EditExercise = () => {
  const navigate = useNavigate();
  const location=useLocation();
  const {exercise}=location.state;
  const [exerciseName, setExerciseName] = useState(exercise.name);
  const [description, setDescription] = useState(exercise.description);
  const [tags, setTags] = useState(exercise.tags.join(", "));

  const handleContinue = () => {
    // Handle the continue action (e.g., form submission, navigation)
    if (!exerciseName || !description || !tags) {
      alert("Please fill all the fields");
      return;
    }
    const tagsArray = tags
      .trim()
      .split(",")
      .map((tag) => tag.trim());

    exercise.name=exerciseName;
    exercise.description=description;
    exercise.tags=tagsArray;
    console.log(exercise);
    // navigate("/add_exercise_2",{ state: { data } });
    navigate("/exercise_edit_2",{state:{exercise}});
  };

  return (
    <Container sx={{ marginTop: 4 }}>
      <Button
        variant="text"
        startIcon={<ArrowBackIosNewIcon />}
        sx={{
          marginBottom: 2,
          fontWeight: 700,
          fontStyle: "normal",
          fontFamily: "var(--Font-1)",
          color: "var(--Gray-700, #344054)",
        }}
        component={Link}
        to="/dashboard"
      >
        Back to Dashboard
      </Button>

      <Box
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          width: "80%",
          margin: "auto",
          padding: 4,
        }}
      >
        <Typography
          sx={{
            fontFamily: "var(--Font-1)",
            fontWeight: 700,
            color: "#101828",
          }}
          variant="h4"
          gutterBottom
        >
          Edit Exercise
        </Typography>
        <Typography variant="h6">Exercise Name</Typography>
        <TextField
          variant="outlined"
          placeholder="Enter exercise name"
          value={exerciseName}
          onChange={(e) => setExerciseName(e.target.value)}
          InputProps={{ sx: { borderRadius: 2 } }}
        />
        <Typography variant="h6">Description</Typography>
        <TextField
          variant="outlined"
          placeholder="Enter description about exercise..."
          multiline
          rows={4}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          InputProps={{ sx: { borderRadius: 2 } }}
        />
        <Typography variant="h6">Category/Tags</Typography>
        <TextField
          variant="outlined"
          placeholder="Add tags separated by commas (,) "
          value={tags}
          onChange={(e) => setTags(e.target.value)}
          InputProps={{ sx: { borderRadius: 2 } }}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 2,
            marginBottom: 8,
          }}
        >
          <Button
            variant="outlined"
            sx={{
              marginRight: 1,
              borderRadius: "8px",
              height: 50,
              width: "280px",
              fontWeight: 700,
              color: "var(--Gray-700, #344054)",
              borderColor: "var(--Gray-700, #344054)",
            }}
          >
            Back
          </Button>
          <Button
            onClick={handleContinue}
            variant="contained"
            sx={{
              background: "var(--Blue-light-500, #0BA5EC)",
              borderRadius: "8px",
              height: 50,
              width: "280px",
              fontWeight: 700,
            }}
          >
            Continue
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default EditExercise;
