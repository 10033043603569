import React from "react";
import { Container, Typography, Grid, Paper, Box } from "@mui/material";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import MonitorHeartOutlinedIcon from "@mui/icons-material/MonitorHeartOutlined";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import { useNavigate } from "react-router-dom";

const StatCard = ({ title, value }) => (
  <Paper sx={{ padding: 3, textAlign: "left" ,border: '1px solid #e0e0e0' ,borderRadius:3}} elevation={0}>
    <Typography
      variant="h6"
      gutterBottom
      sx={{
        // fontStyle: "normal",
        // fontFamily: "var(--Font-1)",
        //test
        color: "#475467",
        fontFamily: "var(--Font-1)",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 500,
      }}
    >
      {title}
    </Typography>
    <Typography
      variant="h4"
      color="black"
      sx={{
        fontWeight: 700,
        fontStyle: "normal",
        fontFamily: "var(--Font-1)",
        color: "var(--Gray-700, #344054)",
      }}
    >
      {value}
    </Typography>
  </Paper>
);

const AdminOptionCard = ({ title, description, icon: Icon }) => (
  <Paper
    sx={{ padding: 2, height: "100%", alignItems: "left", cursor: "pointer",border: '1px solid #e0e0e0' ,borderRadius:3}}
    elevation={0}
  >
    <Box
      sx={{
        width: 34,
        height: 34,
        borderRadius: "50%",
        backgroundColor: "var(--Gray-100, #F2F4F7)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: 2,
      }}
    >
      <Icon sx={{ fontSize: 22 }} />
    </Box>
    <Typography
      variant="h6"
      gutterBottom
      sx={{
        fontWeight: 700,
        fontStyle: "normal",
        fontFamily: "var(--Font-1)",
        color: "var(--Gray-700, #344054)",
      }}
    >
      {title}
    </Typography>
    <Typography
      variant="body2"
      color="textSecondary"
      sx={{
        fontStyle: "normal",
        fontFamily: "var(--Font-1)",
        color: "var(--Gray-700, #344054)",
      }}
    >
      {description}
    </Typography>
  </Paper>
);

const DashBoard = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <Typography
        variant="h4"
        gutterBottom
        sx={{
          marginTop: 8,
          marginLeft: "auto",
          marginRight: "auto",
          fontWeight: 700,
          fontStyle: "normal",
          fontFamily: "var(--Font-1)",
          color: "var(--Gray-700, #344054)",
        }}
      >
        Welcome back,
      </Typography>
      <Grid container spacing={3} sx={{ marginTop: 2 }}>
        <Grid item xs={12} sm={4}>
          <StatCard title="Total Users" value="2,000" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <StatCard title="Sessions Completed" value="456" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <StatCard title="Total Exercises" value="62" />
        </Grid>
      </Grid>
      <Typography
        variant="h5"
        gutterBottom
        sx={{
          marginTop: 3,
          fontWeight: 700,
          fontStyle: "normal",
          fontFamily: "var(--Font-1)",
          color: "var(--Gray-700, #344054)",
        }}
      >
        Admin Options
      </Typography>
      <Grid container spacing={3} sx={{ marginTop: 3 }}>
        <Grid item xs={12} sm={4}>
          <Box onClick={() => navigate("/manage_users")}>
            <AdminOptionCard
              title="Manage Users"
              description="List of users, and their details"
              icon={PeopleOutlineIcon}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box onClick={() => navigate("/manage_sessions")}>
            <AdminOptionCard
              title="Manage Sessions"
              description="List of sessions and their details"
              icon={VideocamOutlinedIcon}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box onClick={() => navigate("/manage_exercises")}>
            <AdminOptionCard
              title="Manage Exercises"
              description="List of exercises with details"
              icon={MonitorHeartOutlinedIcon}
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default DashBoard;
